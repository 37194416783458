import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

let setRedirectObjectCallback = null;

export const pushRedirectMetaCallback = (to, statusCode) => {
  if (!setRedirectObjectCallback)
    throw new Error(
      'setRedirectObjectCallback is not set, this usually happens, when the PrerenderRedirectMeta is not mounted',
    );
  setRedirectObjectCallback(to, statusCode);
};

export const PrerenderRedirectMeta = () => {
  const location = useLocation();
  const [redirectData, _setRedirectData] = useState(null);

  const setRedirectData = (to, statusCode) => {
    if (!to || !statusCode) {
      throw new Error("parameters 'to' and 'statusCode' are required");
    }

    to = to.startsWith('/') ? new URL(to, window.location.href.split('/').slice(0, 3).join('/')).href : to;
    _setRedirectData({ to, statusCode });
  };

  setRedirectObjectCallback = setRedirectData;

  useEffect(() => {
    if (!redirectData) return;
    if (window.location.href === redirectData.to) return;

    _setRedirectData(null);
  }, [location]);

  return (
    <>
      <Helmet>
        {redirectData && <meta name="prerender-status-code" content={redirectData.statusCode}></meta>}
        {redirectData && <meta name="prerender-header" content={`Location: ${redirectData.to}`}></meta>}
      </Helmet>
    </>
  );
};
