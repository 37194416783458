import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './utility/service-worker/service-worker-registration-utility';
import { I18nextProvider } from 'react-i18next';
import i18n from './utility/i18n/i18n-configuration';
import { BrowserRouter } from 'react-router-dom';
import { RootToLocaleRedirectMeta } from './components/meta-tags/root-to-locale-redirect-meta';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <RootToLocaleRedirectMeta />
      <App />
    </BrowserRouter>
  </I18nextProvider>,
);

serviceWorkerRegistration.unregister();
