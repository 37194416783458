import { pushRedirectMetaCallback } from 'components/meta-tags/prerender-redirect-meta';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Utility, signalPrerenderReady, signalPrerenderWait } from 'utility/utility';

function isInternal(url) {
  try {
    const urlObj = new URL(url, window.location.href);
    return urlObj.host === window.location.host;
  } catch (e) {
    console.error('Invalid URL:', e);
    return false;
  }
}

function expandUrl(url) {
  if (isInternal(url)) {
    const urlObj = new URL(url, window.location.href);
    return urlObj.href;
  }
  return url;
}

export function RedirectComponent({ to }) {
  const navigate = useNavigate();

  // this hooks handles base redirecting and prerendering
  useEffect(() => {
    if (!Utility.isClientPrerender()) return;
    signalPrerenderWait();
    return () => signalPrerenderReady();
  }, []);

  useEffect(() => {
    let cleanTo = expandUrl(to);
    if (cleanTo === window.location.href) return;
    if (new URL(cleanTo).host !== window.location.host) {
      window.location.href = cleanTo;
      return;
    }
    cleanTo = cleanTo.replace(window.location.origin, '');
    if (!cleanTo.endsWith('/')) cleanTo += '/';

    // push to prerender meta tags
    pushRedirectMetaCallback(cleanTo, 301);
    // use react to navigate for faster redirects
    navigate(cleanTo, { replace: true });
  }, []);
  return (
    <>
      {/* THIS CODE ALLOWS FOR JAVASCRIPT REDIRECTS BUT IS RELATIVLY SLOW COMPARED TO THE REACT SOLUTION*/}
      {/* <Helmet>
        <meta http-equiv="refresh" content={`0; url=${cleanTo}`} />
      </Helmet> */}
    </>
  );
}
