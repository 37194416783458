import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { signalPrerenderReady, signalPrerenderWait, Utility } from 'utility/utility';

export const NavigateWithParams = ({ to }) => {
  const params = useParams();

  // replace all param placeholders with actual values
  for (const key in params) {
    to = to.replace(':' + key, params[key]);
  }
  // this hooks handles base redirecting and prerendering
  useEffect(() => {
    if (!Utility.isClientPrerender()) return;
    signalPrerenderWait();
    return () => signalPrerenderReady();
  }, []);

  return (
    <>
      <Navigate to={to} replace={true} />
    </>
  );
};
