import { useLocale } from 'hooks/use-locale';
import React from 'react';
import { Helmet } from 'react-helmet';

export function RootToLocaleRedirectMeta() {
  const locale = useLocale();

  return (
    <Helmet>
      {window.location.pathname.length <= 1 && (
        <meta name="prerender-header" content={`Location: /${locale ?? 'en'}/`}></meta>
      )}
      {window.location.pathname.length <= 1 && <meta name="prerender-status-code" content="301"></meta>}
    </Helmet>
  );
}
