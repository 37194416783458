import React, { useEffect } from 'react';
import { Utility, signalPrerenderReady, signalPrerenderWait } from 'utility/utility';

export const ExternalRedirect = ({ path, children }) => {
  window.location.href = path;
  // this hooks handles base redirecting and prerendering
  useEffect(() => {
    if (!Utility.isClientPrerender()) return;
    signalPrerenderWait();
    return () => signalPrerenderReady();
  }, []);
  return <>{children}</>;
};
