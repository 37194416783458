import { useEffect, useState } from 'react';
import { Utility } from '../utility/utility';
import { useLocation } from 'react-router-dom';

const getUrlLocale = () => {
  const pathParts = window.location.pathname.split('/').filter((x) => x && x.trim() !== '');
  return Utility.isLocaleValid(pathParts[0]) ? pathParts[0] : undefined;
};

const getCachedLocale = () => {
  const routeLocale = getUrlLocale();
  if (routeLocale) return routeLocale;
  const cachedJson = window.localStorage.getItem('fetched-locale');

  if (cachedJson) {
    const cached = JSON.parse(cachedJson);
    cached.time = Number(cached.time);
    if (Date.now() - cached.time < 1000 * 60 * 60 * 24 && cached.time > 1736932999605) {
      return cached.locale;
    }
  }
  return undefined;
};

/**
 * A hook that decides the locale of the client based upon the URL or if that fails the cache or if that fails fetches the locale
 * @param {boolean} useCache decides if the cache should be used or not
 * @returns {string | undefined} the locale of the client, undefined if it is not yet decided
 */
export const useLocale = () => {
  const [locale, setLocale] = useState(getCachedLocale());
  const location = useLocation();

  useEffect(() => {
    const tempLocale = getUrlLocale();
    if (tempLocale) {
      // prevent a full side rerender if the locale is the same
      if (tempLocale !== locale) setLocale(tempLocale);

      // disable fetching locale if the locale is already decided
      return;
    }

    Utility.fetchLocale()
      .then((locale) => {
        setLocale(locale);
        window.localStorage.setItem('fetched-locale', JSON.stringify({ locale, time: Date.now() }));
      })
      .catch(() => {
        setLocale('en');
      });
  }, [location]);

  return locale;
};
