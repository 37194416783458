import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Utility } from 'utility/utility';

export function SeoCrawlerRemoveCookieBanner() {
  const [isClientPrerender, setIsClientPrerender] = useState(false);

  useEffect(() => {
    setIsClientPrerender(Utility.isClientPrerender());
  }, []);

  useEffect(() => {
    if (!isClientPrerender) return;

    let interval = null;
    const setRemoveTag = () => {
      // get the root element of the banner
      const banner = document.getElementById('CybotCookiebotDialog');
      if (!banner) return false;
      // set the prerender-remove attribute to make sure prerender acknowledges the removal
      banner.setAttribute('data-prerender-remove', 'true');
      clearInterval(interval);
    };

    if (!setRemoveTag()) {
      interval = setInterval(setRemoveTag, 100);
    }
  }, [isClientPrerender]);

  return (
    <>
      {isClientPrerender && (
        <Helmet>
          {/* set tag to remove cookie banner */}
          <meta name="prerender-remove" content="#CybotCookiebotDialog" />
        </Helmet>
      )}
    </>
  );
}
